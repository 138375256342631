<template>
  <div>
    <filter-component
      v-model="Filter"
    />
    <change-password-component
      v-model="ChangePassword"
      :data="TempUser"
    />
    <change-status-component
      v-model="ChangeStatus"
      :data="TempUser"
    />
    <vs-row
      class="pt-12 mt-1"
      vs-justify="center"
    >
      <vs-col
        vs-w="12"
        type="flex"
        class="mb-5"
        vs-justify="flex-start"
        vs-align="flex-start"
      >
        <vs-button
          color="primary"
          type="gradient"
          icon-pack="feather"
          icon="icon-plus"
          @click="$router.push({name: 'person_create'})"
        >
          {{ $t('Create Person') }}
        </vs-button>
      </vs-col>
      <vs-col vs-w="12">
        <template>
          <div
            id="data-list-list-view"
            class="data-list-container"
          >
            <vs-pagination-table
              model_type="employee"
              :data="persons"
              :table_name="$t('Persons')"
            >
              <template slot-scope="{data}">
                <template>
                  <vs-th sort-key="id">
                    {{ $t('ID') }}
                  </vs-th>
                  <vs-th>
                    {{ $t('Image') }}
                  </vs-th>
                  <vs-th sort-key="first_name">
                    {{ $t('Identity') }}
                  </vs-th>
                  <vs-th sort-key="email">
                    {{ $t('Contact') }}
                  </vs-th>
                  <vs-th sort-key="status">
                    {{ $t('Status') }}
                  </vs-th>
                  <vs-th>
                    {{ $t('Availability') }}
                  </vs-th>
                  <vs-th sort-key="created_at">
                    {{ $t('Created') }} / {{ $t('Updated') }}
                  </vs-th>
                  <vs-th>{{ $t('Options') }}</vs-th>
                </template>
                <template>
                  <vs-tr
                    v-for="(tr, indextr) in data"
                    :key="indextr"
                    :data="tr"
                  >
                    <vs-td :data="tr.id">
                      <vx-badge
                        color="light-primary"
                      >
                        {{ tr.id }}
                      </vx-badge>
                    </vs-td>
                    <vs-td :data="tr.id">
                      <b-avatar :src="$fullImagePath( tr.avatar_url )" />
                    </vs-td>
                    <vs-td :data="tr.name">
                      <div style="width: max-content">
                        <span
                          style="color: rgba(var(--vs-primary), 1);"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'address-card']"
                          />
                        </span>
                        <span>
                          {{ tr.first_name }}</span>
                        <span
                          class="ml-1"
                        >{{ tr.last_name }}</span>
                      </div>
                      <div style="width: max-content">
                        <span
                          v-if="tr.tc"
                          style="color: rgba(var(--vs-primary), 1);"
                        >
                          <font-awesome-icon
                            color="primary"
                            :icon="['fas', 'passport']"
                          />
                        </span>
                        {{ tr.tc }}
                      </div>
                    </vs-td>
                    <vs-td :data="tr.email">
                      <VxTooltip
                        color="primary"
                      >
                        <template #title>
                          {{ $t('Mail') }}
                        </template>
                        <template #text>
                          {{ $t('Click to send email') }}
                        </template>
                        <span
                          @click="SendEmail(tr.email)"
                        >
                          <fa-icon
                            pack="fas"
                            icon="envelope"
                            color="primary"
                          />
                          {{ tr.email }}
                        </span>
                      </VxTooltip>
                      <VxTooltip
                        color="primary"
                      >
                        <template #title>
                          {{ $t('Call') }}
                        </template>
                        <template #text>
                          {{ $t('Click to Call') }}
                        </template>

                        <span @click="MakeCall(tr.phone)"> <fa-icon
                          pack="fas"
                          icon="phone"
                          color="primary"
                        /> {{ tr.phone }}</span>
                      </VxTooltip>
                    </vs-td>
                    <vs-td :data="tr.status">
                      <div style="width: max-content">
                        <VxTooltip
                          :color="tr.status==='Active'?'success':tr.status==='Blocked'?'danger':'warning'"
                        >
                          <template #title>
                            {{ $t('Status') }}
                          </template>
                          <template #text>
                            <span v-if="tr.status==='Active'"> {{ $t('This User is active') }}</span>
                            <span v-else>{{ $t(tr.blocked_reason) }}</span>
                          </template>
                          <vx-badge
                            pack="far"
                            :icon="tr.status==='Active'?'check-circle':tr.status==='Blocked'?'times-circle':'pause-circle'"
                            :color="tr.status==='Active'?'light-success':tr.status==='Blocked'?'light-danger':'light-warning'"
                            :icon-color-bg="tr.status==='Active'?'success':tr.status==='Blocked'?'danger':'warning'"
                            icon-color="null"
                          >
                            {{ $t(tr.status) }}
                          </vx-badge>
                        </VxTooltip>
                      </div>
                    </vs-td>
                    <vs-td :data="tr.availability">
                      <div style="width: max-content">
                        <vx-badge
                          :color="tr.availability==='available'?'light-success':'light-danger'"
                        >
                          {{ $t(tr.availability) }}
                        </vx-badge>
                      </div>
                    </vs-td>
                    <vs-td :data="tr.created_at">
                      <div style="width: max-content">

                        <VxTooltip
                          color="success"
                        >
                          <template #title>
                            {{ $t('Created_At') }}
                          </template>
                          <template #text>
                            {{ tr.created_at }}
                          </template>
                          <vx-badge
                            pack="far"
                            icon="calendar-plus"
                            color="light-success"
                            icon-color-bg="success"
                            icon-color="null"
                          >
                            {{ $t(tr.created_from) }}
                          </vx-badge>
                        </VxTooltip>
                      </div>
                      <div
                        style="width: max-content"
                        class="mt-1"
                      >
                        <VxTooltip
                          color="warning"
                        >
                          <template #title>
                            {{ $t('Updated_At') }}
                          </template>
                          <template #text>
                            {{ tr.updated_at }}
                          </template>
                          <vx-badge
                            pack="fas"
                            icon="calendar-alt"
                            color="light-warning"
                            icon-color-bg="warning"
                            icon-color="null"
                          >
                            {{ $t(tr.updated_from) }}
                          </vx-badge>
                        </VxTooltip>
                      </div>
                    </vs-td>
                    <vs-td :data="tr.id">
                      <div style="width: max-content">
                        <a
                          class="mr-1"
                          @click="ChangePassword=true ,TempUser = tr "
                        >
                          <font-awesome-icon
                            color="rgb(var(--vs-warning))"
                            :icon="['fas', 'key']"
                          />
                        </a>
                        <span
                          style="color: rgba(var(--vs-primary), 1);"
                        >
                          <vs-dropdown vs-trigger-click>
                            <font-awesome-icon
                              color="rgb(var(--vs-primary))"
                              :icon="['fas', 'ellipsis-v']"
                            />
                            <vs-dropdown-menu>
                              <vs-dropdown-item
                                :to="{
                                  name: 'person_cv',
                                  params: { userId: tr.id }
                                }"
                              > {{ $t('User Cv') }} </vs-dropdown-item>
                              <vs-dropdown-item
                                :to="{
                                  name: 'update_cv',
                                  params: { userId: tr.id }
                                }"
                              > {{ $t('update Cv') }} </vs-dropdown-item>
                              <vs-dropdown-item
                                @click="ChangeStatus = true , TempUser = tr"
                              > {{ $t('Change Status') }} </vs-dropdown-item>
                            </vs-dropdown-menu>
                          </vs-dropdown>
                        </span>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </template>
            </vs-pagination-table>
          </div>
        </template>
      </vs-col>
    </vs-row>
    <explanations-component />
  </div>
</template>
<script>
/* eslint-disable */
import VsPaginationTable from "@/views/components/VsPaginationTable";
import ExplanationsComponent from "@/views/dashboard/user/components/userExplantion";
import ChangePasswordComponent from "@/views/dashboard/user/components/ChangePassword";
import ChangeStatusComponent from "@/views/dashboard/user/components/ChangeStatus";
import FilterComponent from "@/views/dashboard/user/components/Filter";
import Vue from "vue";

export default {

  components: {
    FilterComponent,
    ChangeStatusComponent,
    ChangePasswordComponent,
    ExplanationsComponent,
    VsPaginationTable
  },
  data: () => ({
    RoleId: '',
    selectedRoles: [],
    ChangeStatus: false,
    ChangeStatusData: {},
    ChangePassword: false,
    Filter: false,
    UserId: '',
    TempUser: [],
    log: [],
    SelectedRoles: [],
  }),
  computed: {
    persons() {
      return this.$store.getters["employee/persons"]
    },
    isSuperAdmin(){
      return Vue.prototype.$auth.HasRoleAndPermission({role:'SuperAdmin'})
    }
  },
  methods: {
    MakeCall(tel) {
      window.location.href = `tel://${tel}`;
    },
    SendEmail(email) {
      window.location.href = `mailto://${email}`;
    },


  },

}
</script>
<style>
.select-label-placeholder {
  position: absolute;
}

</style>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}


.vs-sidebar {
  z-index: 400000;
}
</style>
