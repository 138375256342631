<template>
  <div>

    <b-modal
      ref="my-modal"
      v-model="IsFilterPopupActiveLocal"
      ok-variant="success"
      :title="$t('Filter')"
      :ok-title="$t('Accept')"
      :cancel-title="$t('Clear Filter')"
      cancel-variant="outline-danger"
      @cancel="ClearFilter()"
      @hidden="close()"
      @ok="FilterUsers()"
    >
      <div class="con-exemple-prompt">
        <vs-col vs-w="12">
          <h6> {{ $t('Filter By Status Of The User') }}</h6>
        </vs-col>
        <vs-col vs-w="12">
          <br>
        </vs-col>
        <vs-col vs-w="12">

          <ul class="centerx">
            <li>
              <vs-radio
                v-model="FilterData.UserStatus"
                color="success"
                vs-value="Active"
              >
                <vx-badge
                  color="light-success"
                >
                  {{ $t('Active') }}
                </vx-badge>
              </vs-radio>
            </li>
            <li>
              <vs-radio
                v-model="FilterData.UserStatus"
                color="warning"
                vs-value="Deactivated"
              >
                <vx-badge
                  color="light-warning"
                >
                  {{ $t('Deactivated') }}
                </vx-badge>
              </vs-radio>
            </li>
            <li>
              <vs-radio
                v-model="FilterData.UserStatus"
                color="danger"
                vs-value="Blocked"
              >
                <vx-badge
                  color="light-danger"
                >
                  {{ $t('Blocked') }}
                </vx-badge>
              </vs-radio>
            </li>
          </ul>
        </vs-col>
        <vs-col vs-w="12">
          <br>
          <br>
        </vs-col>
        <vs-col
          v-if="false"
          vs-w="12"
        >
          <h6> {{ $t('Filter By Balance') }}</h6>
        </vs-col>
        <vs-col
          v-if="false"
          vs-w="12"
        >
          <br>
        </vs-col>
        <vs-col
          v-if="false"
          vs-w="12"
        >
          <div class="con-select-example">
            <vs-select
              v-model="BalanceFilterSelect"
              class="selectExample"
            >
              <vs-select-item
                v-for="(item,index) in BalanceFilterOption"
                :key="index"
                :value="item.value"
                :text="$t(item.text)"
              />
            </vs-select>
            <div v-if="BalanceFilterSelect==='Less than'">
              <vx-input
                v-model="FilterData.LessThan"
                class="mt-2"
                :label="$t('All Balance Less Than')"
                type="number"
              />
            </div>
            <div v-if="BalanceFilterSelect==='Bigger than'">
              <vx-input
                v-model="FilterData.BiggerThan"
                class="mt-2"
                :label="$t('All Balance Bigger Than')"
                type="number"
              />
            </div>
            <div v-if="BalanceFilterSelect==='Between two values'">
              <vx-input
                v-model="FilterData.BigValue"
                class="mt-2"
                :label="$t('Big Value')"
                type="number"
              />
              <vx-input
                v-model="FilterData.SmallValue"
                class="mt-2"
                :label="$t('Small Value')"
                type="number"
              />
            </div>
            <br>
          </div>
        </vs-col>
      </div>
    </b-modal></div>
</template>

<script>
/* eslint-disable */
export default {
  name: "FilterComponent",
  props: ['value'],
  data() {
    return {
      BalanceFilterSelect: this.$t('Between two values'),
      BalanceFilterOption: [
        {text: 'Between two values', value: 'Between two values'},
        {text: 'Bigger than', value: 'Bigger than'},
        {text: 'Less than', value: 'Less than'},
      ],
      FilterData: {
        "UserStatus": null,
        "BiggerThan": null,
        "LessThan": null,
        "BigValue": null,
        "SmallValue": null,

      },
    }
  },
  computed: {
    FilterAttr() {
      let filter = ''
      if(this.FilterData.UserStatus)
        filter+=`&UserStatus=${this.FilterData.UserStatus}`
      if(this.FilterData.BiggerThan)
        filter+=`&BiggerThan=${this.FilterData.BiggerThan}`
      if(this.FilterData.LessThan)
        filter+=`&LessThan=${this.FilterData.LessThan}`
      if(this.FilterData.BigValue)
        filter+=`&BigValue=${this.FilterData.BigValue}`
      if(this.FilterData.SmallValue)
        filter+=`&SmallValue=${this.FilterData.SmallValue}`
      if (filter==='')
        return null
      return filter
    },
    IsFilterPopupActiveLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    close() {
      this.IsFilterPopupActive = false
      this.FilterData.UserStatus = null;
      this.FilterData.BiggerThan = null;
      this.FilterData.LessThan = null;
      this.FilterData.BigValue = null;
      this.FilterData.SmallValue = null;
    },
    FilterUsers() {
      if (this.FilterAttr)
        this.$store.dispatch('paginate/SetFilter', this.FilterAttr)
    },
    ClearFilter(){
      this.FilterData.UserStatus = null;
      this.FilterData.BiggerThan = null;
      this.FilterData.LessThan = null;
      this.FilterData.BigValue = null;
      this.FilterData.SmallValue = null;
      this.$store.dispatch('paginate/SetFilter', this.FilterAttr)
    }
  },
}
</script>

<style scoped>

</style>
